import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../../api/BaseApi";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Tooltip } from "@mui/material";

const AdminLogin = () => {
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  let siteLogo = Cookies.get("siteLogo");
  let captchaKey = Cookies.get("captchaKey");

  const [pageChanges, setPageChanges] = useState();

  // const getSiteData = async () => {
  //   try {
  //     const response = await axios.get(BaseApi + "/getconstant");
  //     setPageChanges(response.data.response.site_logo);
  //   } catch (error) {
  //     console.log("Error getting navbar logo information!");
  //   }
  // };
  useEffect(() => {
    // getSiteData();
    // window.scrollTo(0, 0);
  }, []);

  const getData = async (e) => {
    e.preventDefault();

    try {
      const newErrors = {};

      if (loginData.username === "") {
        newErrors.username = "Username is required";
        window.scrollTo(0, 0);
      }
      if (loginData.password === "") {
        newErrors.password = "Password is required";
        window.scrollTo(0, 0);
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = "Please verify captcha";
      }

      setErrors(newErrors);

      // Function to validate email format
      // function isValidEmail(email) {
      //   // Use a regular expression to validate email format
      //   const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      //   return emailPattern.test(email);
      // }

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/admin/login",
            loginData
          );

          console.log(response);
          let status = response.data.status;
          let tokenFetch = response.data.response.token;
          let fnameFetch = response.data.response.first_name;
          let usertypeFetch = response.data.response.user_type;
          let adminID = response.data.response.adminid;
          setLoading(false);
          console.log("Request sent successfully");

          if (status === 200 && tokenFetch !== "") {
            Cookies.set("token", tokenFetch);
            Cookies.set("adminName", fnameFetch);
            Cookies.set("adminuser_type", usertypeFetch);
            Cookies.set("adminID", adminID);
            // sessionStorage.setItem("token", tokenFetch);
            // sessionStorage.setItem("fname", fnameFetch);
            // sessionStorage.setItem("user_type", usertypeFetch);
            navigate("/admin/admins/dashboard");
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Welcome " + fnameFetch,
            });
          } else if (response.data.status === 500) {
            console.log("Error with credentials!");
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: response.data.message,
            });
          } else {
            console.log("Nothing works");
          }
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Could not log you in!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="container centerContainer">
            <div className="card rounded loginCardStyles">
              <div className="row">
                {/* <div className="col-md-6 leftSection">
                  <img src="/Images/employerlogin.jpg" alt="" />
                </div> */}
                <div className="text-center mt-2">
                  {siteLogo && <img src={siteLogo} alt="logo" />}
                  {!siteLogo && <img src="/Images/logo.png" alt="" />}
                  <div className="card-title h3 pt-5">ADMINISTRATION LOGIN</div>
                  <div className="card-body">
                    <form class="text-center border border-light">
                      <div className="mb-4">
                        <input
                          type="text"
                          id="defaultLoginFormEmail"
                          className={`form-control ${
                            errors.username && "input-error"
                          }`}
                          name="username"
                          value={loginData.username}
                          placeholder="Username"
                          onChange={handleChange}
                        />
                        {errors.username && (
                          <div className="text-danger adminValidation">
                            {errors.username}
                          </div>
                        )}
                      </div>

                      <div className="mb-4 passwordBox">
                        <input
                          type={showPassword ? "text" : "password"} // Use the showPassword state variable to toggle the input type
                          id="defaultLoginFormPassword"
                          className={`form-control ${
                            errors.password && "input-error"
                          }`}
                          name="password"
                          value={loginData.password}
                          placeholder="Password"
                          onChange={handleChange}
                        />
                        <div className="passwordVisibilityAdmin">
                          <p
                            className="btn-primary"
                            type="button"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <>
                                <Tooltip title="Hide Password">
                                  <VisibilityOffIcon />
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title="View Password">
                                  <VisibilityIcon />
                                </Tooltip>
                              </>
                            )}
                          </p>
                        </div>
                        {errors.password && (
                          <div className="text-danger adminValidation">
                            {errors.password}
                          </div>
                        )}
                      </div>

                      <div class="reCaptchaLogin">
                        <ReCAPTCHA
                          sitekey={captchaKey}
                          onChange={(value) => setIsCaptchaVerified(value)}
                        />
                        {errors.captcha && (
                          <div className="text-danger adminValidation">
                            {errors.captcha}
                          </div>
                        )}
                      </div>

                      <button
                        class="btn button1 my-2 "
                        type="submit"
                        onClick={getData}
                      >
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="belowSectionAdminLogin">
              <h3>POWERED BY</h3>
              <img
                src="/Images/adminpanel/logicspice-logo.png"
                alt="Logicspice"
              />
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default AdminLogin;
