import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const JSEducation = () => {
  const [loading, setLoading] = useState(false);
  const [basicCourseList, setBasicCourseList] = useState([]);
  const [educationDetails, setEducationDetails] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const tokenKey = Cookies.get("tokenClient");

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false);

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true);
  };

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false);
  };

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false);

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true);
  };

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false);
  };

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false);

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true);
  };

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false);
  };

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false);

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true);
  };

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/candidates/editEducation",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      setBasicCourseList(response.data.response.basicCourseList);
      setEducationDetails(response.data.response.eduDetails);
      console.log(educationDetails);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setEducationDetails((prevEducationDetails) => {
      const updatedDetails = [...prevEducationDetails]; // Create a shallow copy of the array
      updatedDetails[index] = {
        ...updatedDetails[index], // Create a shallow copy of the specific education detail
        [name]: value, // Update the specific field with the new value
      };
      return updatedDetails; // Return the updated array
    });
  };

  const validateFields = () => {
    const errors = educationDetails.map((edu, index) => ({
      basic_course_id: edu.basic_course_id === "",
      basic_university: edu.basic_university.trim() === "",
      basic_year: edu.basic_year === "",
    }));

    setValidationErrors(errors);
    return errors.every(
      (error) =>
        !error.basic_course_id && !error.basic_university && !error.basic_year
    );
  };
  const handleClick = async () => {
    if (!validateFields()) {
      window.scrollTo(0, 0);
      return;
    }
    try {
      const confirmationResult = await Swal.fire({
        title: "Update Education Details?",
        text: "Do you want to update Education Details?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        setLoading(true);
        await axios.post(
          BaseApi + "/candidates/editEducation",
          { Education: educationDetails }, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        setLoading(false);
        Swal.fire({
          title: "Updated!",
          text: "Education details updated successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed",
        text: "Could not update Education Details. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  const handleRemove = async (id) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Remove Education Details?",
        text: "Do you want to remove this Education Details?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        await axios.post(BaseApi + `/candidates/deleteeducation/${id}`, null, {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        });
        getData();
        Swal.fire({
          title: "Education Detail removed successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed!",
        text: "Could not remove Education Details. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };
  const handleRemoveWithoutId = (indexToRemove) => {
    setEducationDetails((prevEducationDetails) =>
      prevEducationDetails.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleAdd = () => {
    const newQualification = {
      basic_course_id: "", // Set default values for the new block
      basic_university: "",
      basic_year: "",
    };

    setEducationDetails((prevEducationDetails) => [
      ...prevEducationDetails,
      newQualification,
    ]);
  };

  return (
    <>
      <NavBar />
      <div className="container editProfile">
        <div className="row">
          <div className="col-lg-3">
            <JSSidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="mx-3 d-flex PageHeader">
                  <img src="/Images/jobseekerSide/Education-color.png" alt="" />
                  <h3 className="ms-1" style={{ color: "#549cb4" }}>
                    Education Information
                  </h3>
                </div>
                <form>
                  <div className="mt-4 mx-4">
                    {educationDetails.map((j, index) => {
                      return (
                        <>
                          <h4 className="mt-5 mb-5">
                            Qualification {index + 1}:
                          </h4>
                          <div className="form-outline mb-4 DashBoardInputBx">
                            <label
                              className="form-label"
                              htmlFor="form3Example3"
                            >
                              Course Name<span className="RedStar">*</span>
                            </label>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              value={j.basic_course_id}
                              name="basic_course_id"
                              onChange={(e) => handleChange(e, index)}
                            >
                              <option selected value="">
                                Select Course
                              </option>
                              {basicCourseList.map((i) => {
                                return <option value={i.id}>{i.name}</option>;
                              })}
                            </select>
                            <div className="mt-0">
                              {validationErrors[index]?.basic_course_id && (
                                <small className="text-danger">
                                  Course Name is required.
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="form-outline mb-4 DashBoardInputBx">
                            <label
                              className="form-label"
                              htmlFor="form3Example3"
                            >
                              University/Institute
                              <span className="RedStar">*</span>
                            </label>
                            <input
                              type="text"
                              id="form3Example3"
                              className="form-control"
                              placeholder="University/Institute"
                              name="basic_university"
                              value={j.basic_university}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <div className="mt-0">
                              {validationErrors[index]?.basic_university && (
                                <small className="text-danger">
                                  University/Institute Name is required.
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="form-outline mb-4 DashBoardInputBx">
                            <label
                              className="form-label"
                              htmlFor="form3Example3"
                            >
                              Passed In<span className="RedStar">*</span>
                            </label>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              value={j.basic_year}
                              name="basic_year"
                              onChange={(e) => handleChange(e, index)}
                            >
                              <option selected value="">
                                Select Year
                              </option>
                              <option value="2023">2023</option>
                              <option value="2022">2022</option>
                              <option value="2021">2021</option>
                              <option value="2020">2020</option>
                              <option value="2019">2019</option>
                              <option value="2018">2018</option>
                              <option value="2017">2017</option>
                              <option value="2016">2016</option>
                              <option value="2015">2015</option>
                            </select>
                            <div className="mt-0">
                              {validationErrors[index]?.basic_year && (
                                <small className="text-danger">
                                  Passing Year is required.
                                </small>
                              )}
                            </div>
                            <div className="mt-4 removeButtonJS">
                              {j.id ? (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-primary button2"
                                    onClick={() => handleRemove(j.id)}
                                    style={{
                                      color: hoverFourthButtonColor
                                        ? primaryColor
                                        : secondaryColor,
                                      backgroundColor: "white",
                                      border: hoverFourthButtonColor
                                        ? `2px solid ${primaryColor}`
                                        : `2px solid ${secondaryColor}`,
                                    }}
                                    // onMouseEnter={handleFourthButtonMouseEnter}
                                    // onMouseLeave={handleFourthButtonMouseLeave}
                                  >
                                    Remove
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-primary button2"
                                    onClick={() => handleRemoveWithoutId(index)}
                                    style={{
                                      color: hoverFourthButtonColor
                                        ? primaryColor
                                        : secondaryColor,
                                      backgroundColor: "white",
                                      border: hoverFourthButtonColor
                                        ? `2px solid ${primaryColor}`
                                        : `2px solid ${secondaryColor}`,
                                    }}
                                    // onMouseEnter={handleFourthButtonMouseEnter}
                                    // onMouseLeave={handleFourthButtonMouseLeave}
                                  >
                                    Remove
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                    {educationDetails.length <= 0 && (
                      <>
                        <div className="noData">No Information Available</div>
                      </>
                    )}
                  </div>
                </form>
                {educationDetails.length > 0 && (
                  <>
                    <div className="EduAddMore mb-4">
                      <button
                        type="button"
                        className="btn btn-primary button1"
                        onClick={handleAdd}
                        style={{
                          backgroundColor: primaryColor,
                          color: "white",
                          border: primaryColor,
                        }}
                      >
                        ADD MORE
                      </button>
                    </div>
                  </>
                )}
                {educationDetails.length <= 0 && (
                  <>
                    <div className="EduAddMore mb-4">
                      <button
                        type="button"
                        className="btn btn-primary button1"
                        onClick={handleAdd}
                        style={{
                          backgroundColor: primaryColor,
                          color: "white",
                          border: primaryColor,
                        }}
                      >
                        ADD DETAILS
                      </button>
                    </div>
                  </>
                )}
                {educationDetails.length > 0 && (
                  <>
                    <div className="bottomButtonsEducation">
                      <button
                        type="button"
                        className="btn btn-primary button1"
                        onClick={handleClick}
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary button2"
                        style={{
                          color: hoverThirdButtonColor
                            ? primaryColor
                            : secondaryColor,
                          backgroundColor: "white",
                          border: hoverThirdButtonColor
                            ? `2px solid ${primaryColor}`
                            : `2px solid ${secondaryColor}`,
                        }}
                        onMouseEnter={handleThirdButtonMouseEnter}
                        onMouseLeave={handleThirdButtonMouseLeave}
                        onClick={() => navigate("/candidates/myaccount")}
                      >
                        CANCEL
                      </button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSEducation;
